import { deploymentStates } from "../models/service"


// action status
export enum status {
  UNKNOW = -1,
  SLEEPING = 0,
  EXECUTING_MAIN = 1,
  IN_PROGRESS = 2,
  PAUSED = 3,
  SUCCESS = 4,
  ERROR = 5,
  CLOSED = 6,
  REVERTING = 7,
  REVERTED = 8
}



export const affichageStatus = {
  [status.UNKNOW] : {
    message : "unkown",
    class : "pastille-activity",
    tagClass : "wbce-tags-accent",
    activity: false,
  },
   [status.SLEEPING] : {
    message : "waiting",
    class : "pastille-waiting",
    activity: true,
    tagClass : "wbce-tags-waiting"
  },
  [status.EXECUTING_MAIN] : {
    message : "execution",
    class : "pastille-activity",
    activity: true,
    tagClass : "wbce-tags-accent"
  },
  [status.IN_PROGRESS] : {
     message : "activity in progress",
     class : "pastille-activity",
     activity: true,
     tagClass : "wbce-tags-accent"
   },
   [status.PAUSED] : {
    message : "pause",
    class : "pastille-waiting",
    tagClass : "wbce-tags-waiting",
    activity: false,
  },
   [status.SUCCESS] : {
     message : "success",
     class : "pastille-ok",
     tagClass : "wbce-tags-primary",
     activity: false,
   },
   [status.ERROR] : {
     message : "in error",
     class : "pastille-error",
     tagClass : "wbce-tags-error",
     activity: false,
   },
   [status.CLOSED] : {
    message : "closed",
    class : "pastille-inactive",
    tagClass : "wbce-tags-inactive",
    activity: false,
  },
  [status.REVERTING] : {
    message : "reverting",
    class : "pastille-warning",
    activity: true,
    tagClass : "wbce-tags-warning"
  },
  [status.REVERTED] : {
    message : "reverted",
    class : "pastille-ok",
    activity: false,
    tagClass : "wbce-tags-primary"
  }
 }





export enum statusSupervisor {
  UNKNOW = -1,
  SLEEPING = 0,
  IN_PROGRESS = 2,
  PAUSED = 3,
  SUCCESS = 4,
  ERROR = 5,
  WAITING_FIRST_DEPLOYMENT = 6,
  }



  export const affichageStatusSupervisor = {
    [statusSupervisor.UNKNOW] : {
      message : "unkown",
      class : "pastille-activity",
      tagClass : "wbce-tags-accent",
      activity: false,
    },
     [statusSupervisor.SLEEPING] : {
      message : "waiting",
      class : "pastille-waiting",
      activity: true,
      tagClass : "wbce-tags-waiting"
    },
    [statusSupervisor.IN_PROGRESS] : {
       message : "activity in progress",
       class : "pastille-activity",
       activity: true,
       tagClass : "wbce-tags-accent"
     },
     [statusSupervisor.PAUSED] : {
      message : "pause",
      class : "pastille-waiting",
      tagClass : "wbce-tags-waiting",
      activity: false,
    },
     [statusSupervisor.SUCCESS] : {
       message : "success",
       class : "pastille-ok",
       tagClass : "wbce-tags-primary",
       activity: false,
     },
     [statusSupervisor.ERROR] : {
       message : "in error",
       class : "pastille-error",
       tagClass : "wbce-tags-error",
       activity: false,
     },
     [statusSupervisor.WAITING_FIRST_DEPLOYMENT] : {
      message : "waiting first deployment",
      class : "pastille-warning",
      tagClass : "wbce-tags-warning",
      activity: false,
    },
   }

// see if it's a status
export enum statusCommit {
  IN_PROGRESS = 2,
  WARNING = 3,
  SUCCESS = 4,
  ERROR = 5,
  PREVIOUS = 6,
}

export const affichageStatusCommit = {
  [statusCommit.IN_PROGRESS] : {
    message : "deployment in progress",
    status : "in progress",
    tagClass : "wbce-tags-accent",
    class : "pastille-activity",
  },
  [statusCommit.SUCCESS] : {
    message : "current version",
    status : "success",
    tagClass : "wbce-tags-primary",
    class : "pastille-ok",
  },
  [statusCommit.WARNING] : {
    message : "version warning",
    status : "warning",
    tagClass : "wbce-tags-warning",
    class : "pastille-warning",
  },
  [statusCommit.ERROR] : {
    message : "deployment error",
    status : "error",
    tagClass : "wbce-tags-error",
    class : "pastille-error",
  },
  [statusCommit.PREVIOUS] : {
    message : "previous version",
    status : "previous",
    tagClass : "wbce-tags-inactive",
    class : "pastille-inactive",
  },
}


// sync with statusDeployment from back, warning only on front
export const affichageStatusDeployment = {
  [deploymentStates.IN_PROGRESS] : {
    message : "in progress",
    tagClass : "wbce-tags-accent",
    class : "pastille-activity",
  },
  [deploymentStates.SUCCESS] : {
    message : "success",
    tagClass : "wbce-tags-primary",
    class : "pastille-ok",
  },
  [deploymentStates.ERROR] : {
    message : "error",
    tagClass : "wbce-tags-error",
    class : "pastille-error",
  },
  [deploymentStates.WARNING] : { // only on front
    message : "warning",
    tagClass : "wbce-tags-warning",
    class : "pastille-warning",
  }
}
