import {
  Component,
  inject,
  OnInit
} from '@angular/core';
import {
  Router, ActivatedRoute, NavigationEnd
} from '@angular/router';
import { Subscription } from 'rxjs';

import { app_logo, navigationGlobal, navigationService } from '../../enums/navigation';
import { Service } from '../../models/service';
import { WbceServiceManagerService } from '../../service/wbce-service-manager.service';
import {  technos, technosName } from 'src/app/enums/technos';
import { contextes } from 'src/app/enums/contextes';
import { filter } from 'rxjs/operators';
import { WorkspaceManagerService } from 'src/app/service/workspace-manager.service';
import { Project } from 'src/app/models/project';
import { API_SERVICE_TOKEN } from 'src/app/service/api.service';
import { wbceKeycloakScopes } from 'src/app/service/keycloak.service';
import { ROLE_SEP, UNDEFINED_ROLE } from 'src/app/shared-components/wbce-button/wbce-button.component';

export const ALL_SERVICES = "All services";

@Component({
  selector: 'app-left-navigation-list',
  templateUrl: './left-navigation-list.component.html',
  styleUrls: ['./left-navigation-list.component.scss']
})
export class LeftNavigationListComponent implements OnInit {

  subs : Subscription[] = [];

  menu = [];
  app_logo = app_logo;
  technosName = technosName;
  ALL_SERVICES = ALL_SERVICES;

  currentActiveRoute = "infra";

  readRole : string = "";
  undefinedRole : string = UNDEFINED_ROLE;

  contextes : contextes[] = [];
  currentContext : contextes = contextes.STAGING;

  projects : Project[] = [];
  currentProject : Project;
  projectName : string = "";

  services : Service[] = [];
  currentService : Service;
  serviceName : string = "";

  servicesToDisplay : Service[] = [];
  servicesMenu: {name:string, isDisabled:boolean}[]=[];

  tools = [
    {
      name : "Config manager - Infisical",
      logo : "infisical",
      id : technos.INFISICAL
    }
  ]
  /*
,
    {
      name : "Apache APASIX",
      url : "https://apisix.apache.org",
      logo : "apasix"
    }
  */

 router = inject(Router);
 route = inject(ActivatedRoute);
 private api = inject(API_SERVICE_TOKEN);
 wbceServiceManager = inject(WbceServiceManagerService);
 workspaceService = inject(WorkspaceManagerService);

    constructor() {}

    ngOnInit(): void {
      this.subs.push(
        this.router.events.pipe(
          filter(e=> e instanceof NavigationEnd)
        ).subscribe(this.onRouteChange.bind(this)) // ajouter un pipe pour filtrer les events non necessaires ?
      )

      this.subs.push(
        this.wbceServiceManager.currentProject$.subscribe(
          this.onWBCEProjectChange.bind(this)
        )
      )
      this.onRouteChange();
    }
    ngOnDestroy(){
      for(let s of this.subs){
        s.unsubscribe();
      }
    }

    ngDoCheck() {
     // console.log('Change detection triggered');
    }

    onWBCEProjectChange(){
      this.projects = this.workspaceService?.projects; // update before wbceServiceManager
      this.services = this.wbceServiceManager.getServices();

      this.servicesMenu = [{name:ALL_SERVICES, isDisabled:false}]
      this.services.forEach((s) => {
        this.servicesMenu.push({name:s.caculatedDisplayName, isDisabled:s.isDisabled()})
      })

      this.currentService = this.wbceServiceManager.currentService;
      this.servicesToDisplay = this.wbceServiceManager.getServicesToDisplay();

      this.currentContext = this.wbceServiceManager.currentContext;
      this.contextes = this.wbceServiceManager.listeContextes;

      this.currentProject = this.wbceServiceManager.currentProject;

      this.serviceName = this.currentService?.caculatedDisplayName || ALL_SERVICES;
      this.projectName = this.currentProject.projectName;

      this.readRole = this.currentProject._id + ROLE_SEP + contextes.PRODUCTION + ROLE_SEP + wbceKeycloakScopes.GET

      this.menu = this.currentService ?navigationService : navigationGlobal;
      this.servicesToDisplay =this.wbceServiceManager.getServicesToDisplay();

    }

    onRouteChange(){
      this.currentActiveRoute = this.route.firstChild.snapshot.url[0].path
    }

    onProjectServiceSelected(){
      const lastPartOfUrl = this.route.firstChild.snapshot.url[0].path;//Certes...
      this.router.navigate(['../../..',  this.projectName, this.serviceName, this.currentContext, lastPartOfUrl  ], {relativeTo : this.route})
    }

    openContentInNewTab(service:Service, context : contextes){
      this.api.getUrl(service, context).subscribe(url=>{
        if(url)window.open(url, '_blank');
      })
    }

    openToolInNewTab(tool : any){

      if(tool.id === technos.INFISICAL){
        this.workspaceService.openInfisicalService()
      }
     /* this.api.getToolUrl().subscribe(url=>{
        if(url)window.open(url, '_blank');
      })*/

    }
  }
